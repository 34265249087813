/* Variables overrides */
$primary: #978D5C;
$secondary: #FF8424;
$tertiary: #191718;
$border-color: #ccc;

$body-color:#222;
$headings-color: #333;
$link-color: $secondary;
$breadcrumb-text-color: #333;

$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;

$body-bg: #f7f7f7;

$search-home-bg-color: $primary;
$search-overview-bg-color: $border-color;
$static_header_area_form_background_color: $tertiary;

$mobile-header-background: white;
$mobile-header-color: $primary;

$navbar-dark-color: $primary;
$navbar-dark-active-color: $link-color;
$navbar-dark-hover-color: $link-color;

$footer-background-color: lighten($tertiary, 5%);
$bottom-footer-bg-color: $tertiary;

$border-radius: 4px;

@import url('https://fonts.googleapis.com/css?family=Open+Sans');
$font-family-sans-serif: 'Open Sans', Arial, Helvetica, "Nimbus Sans L", sans-serif;
$font-family-base: 'Open Sans', Arial, Helvetica, "Nimbus Sans L", sans-serif;
$btn-font-family: 'Open Sans', Arial, Helvetica, "Nimbus Sans L", sans-serif;
$headings-font-family: 'Open Sans', Arial, Helvetica, "Nimbus Sans L", sans-serif;

/* Flean base imports */
@import "../../flean_base_2019/sass/imports";

/* Extra overrides */
.node-type-overview-page #block-helper-search-search-holiday-homes h2 {
  color: $tertiary;
  font-weight: 700;
}
